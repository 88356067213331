import { axiosInstance } from "../../api/axiosinstance";

export const GeneralService = {
  getAll: async (url, payload) => {
    let responseData = [];

    await axiosInstance
      .post(url, payload)
      .then((res) => {
        responseData = res.data;
      })
      .catch((err) => {
        console.log("ERROR", err);
        throw err;
      });
    return responseData;
  },
  getUrl: async (url, payload) => {
    let responseData = [];
    await axiosInstance
      .get(url, payload)
      .then((res) => {
        responseData = res.data;
      })
      .catch((err) => {
        console.log("ERROR", err);
        throw err;
      });

    return responseData;
  },
};
