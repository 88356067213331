import React, { useState, useEffect } from "react";
import PageContent from "../../components/PageContent";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";
import { Helmet } from "react-helmet";

export default function AffiliatesDetail() {
  const [affiliates, setaffiliates] = useState([]);
  const { t, i18n } = useTranslation();
  const { slug } = useParams();

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 9,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;
        setaffiliates(datalist);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);

  return (
    <>
      <Container>
        {affiliates &&
          affiliates.map((item, key) => {
            const textData = JSON.parse(item.Text);
            const { SeoUrl, Title, Content, SeoTitle, SeoContent } = textData;
            <Helmet>
              <title>{SeoTitle}</title>
              <meta name="description" content={SeoContent} />
            </Helmet>;
            if (SeoUrl === slug) {
              return <PageContent Title={Title} Content={Content} />;
            }
          })}
      </Container>
    </>
  );
}
