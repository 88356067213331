import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, CardMedia, Container, List, ListItem } from "@mui/material";
import styles from "./locations.module.scss";
import { useTranslation } from "react-i18next";

function LocationCard({
  country,
  phone,
  email,
  address,
  imageUrl,
  subtext,
  adres,
}) {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);
  return (
    <Grid sm={4} md={3} className={styles.locations} key={1}>
      <CardMedia component="img" image={imageUrl} alt={country} />
      <Box className={styles.title}>
        <Typography variant="h4"> {country} </Typography>
        <Typography variant="h5"> {subtext} </Typography>
      </Box>
      <List>
        {phone && (
          <ListItem>
            <Grid spacing={2}>
              <PhoneIcon></PhoneIcon>
            </Grid>
            <Grid xs={12} sm container className={styles.footerContent}>
              <Grid direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6">
                    {t("contactDetail.turkiye.title")}
                  </Typography>
                </Grid>
                <Grid className={styles.contentText}>
                  <Typography variant="span">{phone}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        )}
        <ListItem>
          <Grid spacing={2}>
            <EmailIcon></EmailIcon>
          </Grid>
          <Grid xs={12} sm container className={styles.footerContent}>
            <Grid direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h6">{t("contactDetail.email")}</Typography>
              </Grid>
              <Grid className={styles.contentText}>
                <Typography variant="span">{email}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid spacing={2}>
            <LocationOnIcon></LocationOnIcon>
          </Grid>
          <Grid xs={12} sm container className={styles.footerContent}>
            <Grid direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h6">{t("contactDetail.adres")}</Typography>
              </Grid>
              <Grid className={styles.contentText}>
                <Typography variant="span">{address}</Typography>
              </Grid>
              {adres && (
                <Grid
                  className={styles.contentText}
                  borderTop={1}
                  marginTop={1}
                  paddingBottom={1}
                >
                  <Typography variant="span">{adres}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Grid>
  );
}

function Locations() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);
  const locations = [
    {
      country: "TÜRKİYE",
      subtext: "Dgpays Genel Merkez",
      phone: "+90 212 290 40 10",
      email: "info@dgpays.com",
      address:
        "Maslak Mah. Eski Büyükdere Cad. Koç Kaya As Plaza No:1 Kat:16 Sarıyer – İstanbul",
      adres:
        "DgpaysIT Digitalpark Teknopark Barbaros, Şebboy Sk. No:4/1 İç Kapı No:1, 34758 Ataşehir - İstanbul",
      imageUrl: "/document/images/contacts/turkiye.png",
    },
    {
      country: "DUBAI",
      subtext: "Dgpays Middle East and Africa",
      phone: "+971 4 4392600",
      email: "intsales@dgpays.com",
      address:
        "Office 2601, East Tower, Index Tower, DIFC, POBox 482018, Dubai, United Arab Emirates",
      imageUrl: "/document/images/contacts/dubai.png",
    },
    {
      country: "LUXEMBURG",
      subtext: "Dgpays Société à Responsabilité Limitée",
      phone: "",
      email: "intsales@dgpays.com",
      address: "4 rue Jean Pierre Probst 2352 Luxemburg",
      imageUrl: "/document/images/contacts/luksemburg.png",
    },
    {
      country: "AMERICA",
      subtext: "Dgpays North America, LLC",
      phone: "",
      email: "intsales@dgpays.com",
      address: "1209 Orange Street Wilmington 19801, DE",
      imageUrl: "/document/images/contacts/amerika.png",
    },
  ];

  return (
    <>
      <Container>
        <Typography variant="h2">{t("contactDetail.office")}</Typography>
      </Container>
      <Grid columnSpacing={{ md: 12 }} className={styles.footerHome} key={2}>
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className={styles.bottom}
            >
              {locations.map((location, index) => (
                <LocationCard key={index} {...location} />
              ))}
            </Grid>
          </Box>
        </Container>
      </Grid>
    </>
  );
}

export default Locations;
