import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";
import styles from "./news.module.scss";
import New from "../Home/Sections/New";
import config from "../../api/config.json";
import { Helmet } from "react-helmet";
import { useTheme } from "@mui/material/styles";

export default function NewsDetail() {
  const [news, setnews] = useState([]);
  const { t, i18n } = useTranslation();
  const { slug } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 0,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;
        setnews(datalist);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);

  return (
    <>
      <Container>
        {news &&
          news.map((item) => {
            const textData = JSON.parse(item.Text);
            const {
              PdfFile,
              SeoUrl,
              Title,
              Content,
              Image,
              SeoTitle,
              SeoContent,
            } = textData;

            if (SeoUrl === slug) {
              return (
                <Container>
                  <Helmet>
                    <title>{SeoTitle}</title>
                    <meta name="description" content={SeoContent} />
                  </Helmet>
                  <Grid
                    container
                    spacing={{ xs: 0, sm: 1, md: 2 }}
                    columns={12}
                    className={styles.newsDetail}
                  >
                    <Box mt={5}>
                      <CardMedia
                        component="img"
                        image={config[0].URL + `${Image}`}
                      />
                      <Box className={styles.title} m={isMobile ? 5 : 10}>
                        <Typography variant="h1"> {Title}</Typography>

                        <div dangerouslySetInnerHTML={{ __html: Content }} />
                      </Box>
                      <Box m={isMobile ? 3 : 10}>
                        <Button
                          variant="contained"
                          href={config[0].URL + PdfFile}
                        >
                          {t("newsBtn")}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Container>
              );
            }
          })}
      </Container>

      <Grid spacing={2} className={styles.digerHaberler}>
        <Container>
          <New subTitle={t("suggestions")} title={t("otherNews")} />
        </Container>
      </Grid>
    </>
  );
}
