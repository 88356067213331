import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import locales from "./locales";

const resources = {
  en: {
    translation: locales.en,
  },
  tr: {
    translation: locales.tr,
  },
};

const getLanguage = () => {
  const ls = localStorage.getItem("language");
  if (ls !== null) {
    if (Object.keys(resources).find((f) => f === ls) !== undefined) {
      return ls;
    } else {
      localStorage.setItem("language", "en");
      return "en";
    }
  } else {
    let parsed = navigator.language;
    if (parsed.includes("-")) {
      parsed = parsed.split("-")[0];
    }
    localStorage.setItem("language", parsed);
    return parsed;
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("i18nextLng") || "tr",
  fallbackLng: {
    default: ["en"],
  },

  interpolation: {
    escapeValue: false,
  },
  saveMissing: true,
  parseMissingKeyHandler: (key) => {
    return `missing translation "${key}"`;
  },
  react: {
    transWrapTextNodes: "span",
  },
});
export default i18n;
