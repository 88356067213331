import React, { useState, useEffect } from "react";
import PageContent from "../../components/PageContent";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";

export default function Answers() {
  const [answers, setanswers] = useState([]);
  const { t, i18n } = useTranslation();
  const { slug } = useParams();

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 8,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;
        setanswers(datalist);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);

  return (
    <>
      <Container>
        {answers &&
          answers.map((item) => {
            const textData = JSON.parse(item.Text);
            const { Title, Content, Image, SeoTitle, SeoContent } = textData;

            if (textData.SeoUrl === slug) {
              return (
                <PageContent
                  Title={Title}
                  Content={Content}
                  Image={Image}
                  SeoContent={SeoContent}
                  SeoTitle={SeoTitle}
                />
              );
            }
          })}
      </Container>
    </>
  );
}
